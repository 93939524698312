.searchForm {
  :global {
    .ant-form-item {
      display: flex;
      margin-right: 0;
      margin-bottom: 24px;
      > .ant-form-item-label {
        width: auto;
        padding-right: 8px;
        line-height: 32px;
      }
      .ant-form-item-control {
        line-height: 32px;
      }
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }

  .submitBtns {
    display: block;
    margin-bottom: 24px;
    white-space: nowrap;
  }
}

.btnGroup {
  margin-bottom: 24px;

  button {
    margin-left: 12px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.map--placeholder {
  height: 400px;
}

@primary-color: #fe9704;